import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import { Container, Row, Col } from 'reactstrap'
import Link from '../components/link'
import BoxBorderless from '../components/boxBorderless'
import styled from 'styled-components'
import Image from "gatsby-image"
import Hero from '../components/Hero'
import navigation from '../data/navigation';
import useSiteMetadata from '../hooks/use-site-config'
import {
  isAndroid,
  isIOS,
  isMobile,
  isTablet,
} from "react-device-detect";

export const listQuery = graphql`
query ListQuery {
  site {
    siteMetadata {
      title
      description
      
    }
  }
  allMarkdownRemark {
    edges {
      node {
        fields{
          slug
        }
        frontmatter {
          type
          title
          childrenPath
          description
          order
          link
          image{
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed
              }
            }
        }
        imageHover{
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
         }
        }
      }
    }
  }
}
`;

const ProcedureImage = styled(Image)`
  width: 150px !important;
  height:  150px !important;
  display: inline-block !important;
  position: relative !important;
  `
  const ProcedureImageHover = styled(Image)`
  width: 150px !important;
  height:  150px !important;
   display: none!important;
  position: unset !important;
  z-index: 9999 !important;
  `

const HoverContainer = styled.div`
margin-top: 1rem;
margin-left: auto;
margin-right: auto;
position: relative;	
width: 150px;
height:  150px;
&:hover .procedureNoColor{
  display: inline-block !important;;
}
`
const LinksImage = styled(Image)`
 
  max-width: 150px;
  max-height: 150px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
`

const AppsImage = styled(Image)`
 
  max-width: 150px;
  max-height: 200px;
  margin-top: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
`

const BoxBorderlessApp = styled.div`
 
  border-radius: 1em;
  color: #000000;
  transition-duration: 0.3s;
  text-align: center;
 

`

let Service = ({ image, imageHover, link }) => (
  <Col lg="2" md="3" sm="6" className="col-half-offset">
    <Link to={link} >
      <HoverContainer>
      <ProcedureImage fixed={image} />
      <ProcedureImageHover loading="eager" fixed={imageHover} className="procedureNoColor" />
      </HoverContainer>
    </Link>
  </Col>
)


let Links = ({ title, image, link, middle}) => (
<Col >
    <Link to={link}>
      {(middle % 2 === 0)?
        (<BoxBorderless >
        <LinksImage className="mt-3" fixed={image} imgStyle={{
          objectFit: "contain",
        }} />
      <h5 className="mt-3">{title}</h5>
    </BoxBorderless>):
      (<BoxBorderless className="lateralBorders">
        <LinksImage className="mt-3" fixed={image} imgStyle={{
          objectFit: "contain",
        }} />
      <h5 className="mt-3">{title}</h5>
    </BoxBorderless> )
    }  
  </Link>
  </Col>
)


let App = ({ title, image, baseLink, description, link, internalLink }) => (
 
  <Col lg="4" md="4" sm="12">
    <Link to={link===""? internalLink: baseLink+link}>
      <BoxBorderlessApp>
        <AppsImage className="mt-3" fixed={image} imgStyle={{
          objectFit: "contain",
        }} />
        <h5 className="mt-3 titleApp">{title}</h5>
        <p className="mt-3 descriptionApp">{description}</p>
      </BoxBorderlessApp>
    </Link>
  </Col>

)

function sortList(list) {
  return list.sort((a, b) =>  a.node.frontmatter.order - b.node.frontmatter.order);
}



export default function IndexPage(props) {  
  const { appleStore, playStore } = useSiteMetadata()
  const { description } = props.data.site.siteMetadata
  const contentList = props.data.allMarkdownRemark;
  const linkContentList = contentList.edges.filter(edge => edge.node.frontmatter.type && edge.node.frontmatter.type === "link");
  const appContentList = contentList.edges.filter(edge => edge.node.frontmatter.type && edge.node.frontmatter.type === "app");
  const procedureCategoryList = contentList.edges.filter(edge => edge.node.frontmatter.type && edge.node.frontmatter.type === "index");
  let linkCasillero= navigation[0].url;
  sortList(linkContentList);
  sortList(appContentList);
  sortList(procedureCategoryList);
  return (
    <Layout>
      <Hero title={description} searchForm={true} />
      <Container >
        <Row>
              {linkContentList.map(({ node }, i) => (
                <Links image={node.frontmatter.image.childImageSharp.fixed}
                  link={node.fields.slug} middle={i}/>
            ))}
        </Row>
        <hr></hr>
        <Row className="justify-content">
          {isIOS && appContentList.map(({ node }, i) => (
              < App title={node.frontmatter.title} image={node.frontmatter.image.childImageSharp.fixed}
              baseLink={appleStore} description={node.frontmatter.description} link={node.frontmatter.link}
              internalLink={node.fields.slug}/>
          ))}
              {isAndroid && appContentList.map(({ node }, i) => (
              < App title={node.frontmatter.title} image={node.frontmatter.image.childImageSharp.fixed}
              baseLink={playStore} description={node.frontmatter.description} link={node.frontmatter.link}
              internalLink={node.fields.slug}/>
          ))}
              {!isIOS &&  !isAndroid && appContentList.map(({ node }, i) => (
              < App title={node.frontmatter.title} image={node.frontmatter.image.childImageSharp.fixed}
              baseLink={linkCasillero} description={node.frontmatter.description} link={node.frontmatter.link}
              internalLink={node.fields.slug}/>
          ))}
        </Row>
        <hr></hr>
        <h2 className="text-center mb-4">¿Qué trámites puedo realizar?</h2>
        <Row>
          {procedureCategoryList.map(({ node }, i) => (
            <Service title={node.frontmatter.title} image={node.frontmatter.image.childImageSharp.fixed}
            imageHover={node.frontmatter.imageHover.childImageSharp.fixed}
              link={node.fields.slug} />
          ))}
        </Row>
      </Container>
    </Layout>
  );
}