import React from 'react'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import useSiteImages from '../hooks/use-site-images'
import { colors } from '../tokens'
import SearchForm from '../components/searchFormIndex'
import { Row, Col } from 'reactstrap'


const HeroContainer = styled.div`
  position: relative;
  display: table;
  width: 100%;
  height: 600px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media only screen and (max-width: 600px){
    height: 350px;
  }
`

const TitleContainer = styled.div`
  vertical-align: middle;
  text-align: center;
  width: 90%;
  margin: auto;
  @media only screen and (max-width: 600px){
    width: 80%;
  }
`

const HeroTitle = styled.h1`
  font-weight: 700;
  font-size: 300%;
  margin: auto;
  width 86%;
  color: ${colors.black};
  @media only screen and (max-width: 600px){
      font-size: 180%;
      width: 95%;
  }
  @media only screen and (min-width: 600px){
    font-size: 200%;
    width: 86%;
}
@media only screen and (min-width: 1200px){
  font-size: 250%;
  width: 86%;
}
  @media only screen and (min-width: 1600px){
    font-size: 300%;
    width: auto;
}


`

const HeroSubTitle = styled.h2`
  margin: 10px 50px;
  color: ${colors.white};
  text-shadow: 1px 1px 4px rgba(34, 34, 34, 0.85);
`

const Hero = props => {
  const { siteCover } = useSiteMetadata()
  const { fluid } = useSiteImages(siteCover)
  const heroImg = props.heroImg || fluid.src
  const classStyle = props.classStyle

  return (
    <HeroContainer style={{ backgroundImage: `url("${heroImg}")` }} className={classStyle}>
      <Row className="justify-content-center" >
      <Col  sm="8" md="7" xl="6">
      <TitleContainer>
        <HeroTitle>{props.title}</HeroTitle>
        {props.subTitle && <HeroSubTitle>{props.subTitle}</HeroSubTitle>}
        {props.searchForm &&  <SearchForm/>}
      </TitleContainer>
      </Col>
      </Row>
    </HeroContainer>
  )
}

export default Hero