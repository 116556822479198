import React from "react"
import styled from 'styled-components'
import { FaSearch } from 'react-icons/fa';

const SearchContainer = styled.div`
  position: relative;
  color: #aaaaaa80;
  font-size: 20px;
   input{
  width: 100%;
  height: 50px;
  border: 1px solid #fff;
  border-radius:  0.6rem;
  text-indent: 40px;
 }`

const SearchForm = ({ query }) => (
  <form role="search" method="GET" action="/search"  className="index_form">
    <SearchContainer>
      <FaSearch  style={{position: 'absolute', top: '4px', width: '40px', height: '40px', marginLeft:'2px'}}  />
      <input
        type="search"
        id="search-input"
        name="keywords"
        placeholder="Buscar trámites"
        aria-controls="search-results-count"
        value={query}
      />
    </SearchContainer>
  
  </form>
)

export default SearchForm
